import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { Container, Typography, Paper, makeStyles, Grid } from '@material-ui/core';
import _ from 'lodash';
import semver from 'semver';

import { IApiResponse, IArchItem, IFeatureFlag, ISite } from '@halio-inc/api-client';

import LoadingSpinner from 'components/LoadingSpinner';
import ApiClient from 'lib/web/api-client';

import SiteTable from './components/site-table';
import { normalizeVersion } from './components/version-info/util';
import { ISiteSummary } from './state';

const siteService = ApiClient.getSites()
const archItemsService = ApiClient.getArchItems()
const featureService = ApiClient.getFeatureFlags()

function flattenArchItems<T>(items: T[]): T[] {
  const flatten = (items: any[]): any[] => {
    const flat: T[] = [];
    items.forEach((item: any) => {
      const copy = Object.assign({}, item);
      delete copy.items;
      flat.push(copy);
      if (item.hasOwnProperty('items') && item.items.length > 0) {
        flat.push(...flatten(item.items));
      }
    });
    return flat;
  };

  return flatten(items);
};

const isOtherDeviceType = (item: IArchItem) => {
  if (['keypad', 'arc-reactor', 'range-extender'].includes(item.type)) {
    return true;
  }

  if (item.type === 'gateway' && item.subtype === 'gcss') {
    return true;
  }

  return false;
}

const isSiteFeatureEnabled = (site: ISite, feature: IFeatureFlag | undefined): boolean => {
  return feature !== undefined && feature.sites.some((s: ISite) => s.id === site.id)
}

const getMinVersion = (a: string, b: string): string => {
  const aNorm = normalizeVersion(a);
  const bNorm = normalizeVersion(b);

  if (!aNorm || !semver.valid(aNorm)) {
    return bNorm;
  } else if (!bNorm || !semver.valid(bNorm)) {
    return aNorm;
  }

  return semver.lt(aNorm, bNorm) ? aNorm : bNorm;
}

const getSiteSummary = (site: ISite, siteSummaries: IArchItem[], features: IFeatureFlag[]): ISiteSummary => {
  const glareFeature = features.find(
    (f: IFeatureFlag) => f.feature === 'GlareControl',
  );
  const siteSummary = {
    site,
    enabled: true,
    loaded: false,
    devices: {
      gateway: { online: 0, total: 0 },
      driver: { online: 0, total: 0 },
      other: { online: 0, total: 0 },
    },
    battery: {
      ok: 0,
      total: 0,
    },
    min_versions: {
      driver: '',
      gateway: '',
      gcss: '',
    },
    feature: {
      glareControl: isSiteFeatureEnabled(site, glareFeature),
    },
  };

  //
  // Process Site-level values
  //

  if (siteSummaries) {
    if (siteSummaries[0]) {
      siteSummary.enabled =
        _
          .get(
            siteSummaries[0],
            'attributes.siteinfo.hideFromSupportDashboard.value',
            '0',
          ) !== '1';
    }

    siteSummaries.forEach(item => {
      if (
        _
          .get(
            item,
            'attributes.support.hideFromSupportDashboard.value',
            '0',
          ) === '1'
      ) {
        return;
      }

      if (item.type === 'gateway' && item.subtype !== 'gcss') {
        // Online Gateways (count/total)
        siteSummary.devices.gateway.total++;

        if (item.isAvailable) {
          siteSummary.devices.gateway.online++;
        }

        // TODO: Gateway Version (lowest) - need robust & consistent comparison
        const firmwareVersion = _.get(item, 'meta.firmware');
        if (firmwareVersion) {
          siteSummary.min_versions.gateway = getMinVersion(
            siteSummary.min_versions.gateway,
            firmwareVersion,
          );
        }
      } else if (item.type === 'driver') {
        // Online Drivers (count/total)
        siteSummary.devices.driver.total++;

        if (item.isAvailable) {
          siteSummary.devices.driver.online++;
        }

        // TODO: Driver Firmware Version (lowest) - need robust & consistent comparison
        const firmwareVersion = _.get(item, 'meta.firmware');
        if (firmwareVersion) {
          siteSummary.min_versions.driver = getMinVersion(
            siteSummary.min_versions.driver,
            firmwareVersion,
          );
        }
      } else if (isOtherDeviceType(item)) {
        // Online Other (count/total)
        siteSummary.devices.other.total++;

        if (item.isAvailable) {
          siteSummary.devices.other.online++;
        }
      }

      if (item.type === 'gateway' && item.subtype === 'gcss') {
        // TODO: GCSS Version (lowest) - need robust & consistent comparison
        const firmwareVersion = _.get(item, 'meta.firmware');
        if (firmwareVersion) {
          siteSummary.min_versions.gcss = getMinVersion(
            siteSummary.min_versions.gcss,
            firmwareVersion,
          );
        }
      }

      // Keypad Battery Level (ok-level/total)
      if (item.type === 'keypad') {
        siteSummary.battery.total++;

        if (_.get(item, 'meta.battery_voltage') > 2900) {
          siteSummary.battery.ok++;
        }
      }
    });

    siteSummary.loaded = true;
  }

  return siteSummary;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  loadIndicator: {
    textAlign: 'right',
    paddingBottom: theme.spacing(2),
  }
}))

const SupportDashboard: React.FC = () => {
  const { t } = useTranslation(['dashboard', 'shared'])
  const classes = useStyles()
  const queryClient = useQueryClient();

  // Get all sites
  const {
    data: sites,
    isError: isErrorSites,
    isFetching: isFetchingSites,
  } = useQuery<IApiResponse<ISite[]>>(['sites'], () => siteService.getSites({ grant: 'shared-support' }));

  // Get all features
  const {
    data: features,
    isFetching: isFetchingFeatures,
  } = useQuery<IApiResponse<IFeatureFlag[]>>(['features'], () => featureService.getFeatureFlags({}));

  // For each site, get the arch items for that site
  const queries = useQueries((sites?.results ?? []).map(s => {
    return {
      queryKey: ['arch-items', s.id],
      enabled: features?.success && sites?.success,
      queryFn: (ctx) => archItemsService.getArchItems({ siteId: s.id }).then(ai => {
        const siteID = ctx.queryKey[1] as string
        const site = sites?.results?.find(s => s.id === siteID)
        if (!site) throw new Error('cannot find site')

        const items = flattenArchItems(ai.results || [])
        return getSiteSummary(site, items, features?.results as IFeatureFlag[])
      }),
    }
  }));

  // Cancel all request for arch items when leaving the page
  useEffect(() => {
    if (queryClient) {
      return () => {
        queryClient.cancelQueries('arch-items', {
          active: true, inactive: true, fetching: true,
        })
      }
    }
    return undefined;
  }, [queryClient])

  // Show spinner if features and sites are still fetching
  const isLoading = isFetchingFeatures || isFetchingSites;

  // Loop over sites and return the summary if arch items for that site have been pulled
  // otherwise show a shell that has a loaded flag to show skeleton
  const siteSummeries = (sites?.results ?? []).map<ISiteSummary>(s => {
    const summary = queries.find(q => q.isSuccess && (q.data as ISiteSummary).site === s)
    if (summary !== undefined) {
      return summary.data as ISiteSummary
    }
    return {
      site: s,
      enabled: true,
      loaded: false,
      devices: {
        gateway: { online: 0, total: 0 },
        driver: { online: 0, total: 0 },
        other: { online: 0, total: 0 },
      },
      battery: {
        ok: 0,
        total: 0,
      },
      min_versions: {
        driver: '',
        gateway: '',
        gcss: '',
      },
      feature: {
        glareControl: false,
      },
    };
  })

  if (isErrorSites) {
    return <p>Unable to load site info.</p>;
  }

  if (isLoading) {
    return <LoadingSpinner width="50px" height="50px" />
  }

  const loaded = siteSummeries.filter(s => s.loaded)
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{t('title') + ' - Global System Status'}</Typography>
          <Paper>
            <Container maxWidth="lg">
              <SiteTable siteSummaries={siteSummeries} />
              <div className={classes.loadIndicator}>{loaded.length}/{sites?.results?.length} loaded</div>
            </Container>
          </Paper>

        </Grid>
      </Grid>
    </Container>
  );
}

export default SupportDashboard;