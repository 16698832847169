interface IFilterAndFlattenArchItems {
  items: IFilterAndFlattenArchItems[];
  type: string;
  subtype: string;
}

export function flattenArchItems<T extends IFilterAndFlattenArchItems>(
  items: T[],
  types?: string[],
  subtypes?: string[],
): T[] {
  const flatten = (items: any[]): any[] => {
    const flat: T[] = [];
    items.forEach((item: T) => {
      const copy = Object.assign({}, item);
      copy.items = [];
      flat.push(copy);
      if (item.items.length > 0) {
        flat.push(...flatten(item.items));
      }
    });
    return flat;
  };

  return flatten(items).filter(i => {
    let include = types === undefined && subtypes === undefined;
    if (types) {
      include = types.includes(i.type);
    }
    if (include && subtypes) {
      include = subtypes.includes(i.subtype);
    }
    return include;
  });
}
