import React, { useContext, useCallback, useState } from "react";
import { AppBar, Toolbar, IconButton, makeStyles, Button, Menu, MenuItem, useMediaQuery, Theme } from "@material-ui/core";
import { AccountCircleRounded, Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";

import LanguageTextSwitcher from "components/language-text-switcher";
import { AuthorizationContext } from "lib/shared/authorization";

import Logo from 'images/logo.png'

const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: theme.palette.common.white
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: '30px',
  },
  spacer: {
    flexGrow: 1,
  }
}))

interface IHeaderProps
  extends React.HTMLAttributes<HTMLStyleElement> {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<IHeaderProps> = ({
  className,
  setDrawerOpen,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(t => t.breakpoints.down('sm'))
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const handleSetUserMenuAnchor = useCallback((event) => {
    setUserMenuAnchor(event.target);
  }, [setUserMenuAnchor]);
  const closeUserMenu = useCallback(() => {
    setUserMenuAnchor(null);
  }, [setUserMenuAnchor]);
  const { user, logout } = useContext(AuthorizationContext);

  const whiteButton = clsx(classes.menuButton, classes.whiteText);

  if (!user) return null;

  return (
    <AppBar className={clsx(className)} position="fixed">
      <Toolbar>
        <IconButton className={whiteButton} onClick={() => setDrawerOpen(o => !o)}>
          <MenuIcon />
        </IconButton>
        <img src={Logo} className={classes.logo} alt="logo" />
        <div className={classes.spacer} />
        <React.Suspense
          fallback={'Loading...'}
        >
          <LanguageTextSwitcher className={whiteButton} />
        </React.Suspense>
        {isSmall ? (
          <IconButton
            className={whiteButton}
            onClick={handleSetUserMenuAnchor}
            hidden={!isSmall}
          >
            <AccountCircleRounded />
          </IconButton>
        ) : (
          <Button
            className={whiteButton}
            onClick={handleSetUserMenuAnchor}
            hidden={isSmall}
          >
            {user.name}
          </Button>
        )}
        <Menu
          open={Boolean(userMenuAnchor)}
          onClose={closeUserMenu}
          anchorEl={userMenuAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        >
          <MenuItem onClick={() => logout()}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
