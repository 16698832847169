import { ListItem } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLinkProps as RRDNavLinkProps, Route } from 'react-router-dom';

interface INavLinkProps extends RRDNavLinkProps {
  flaticon?: string;
  children: any;
  [rest: string]: any;
}

const NavLinkFn:React.FC<INavLinkProps> = (props) => {
  const processChildren = (context: RouteComponentProps) => {
    const isActive = !!(props.isActive
      ? props.isActive(context.match, context.location)
      : context.match);

    return (
      <ListItem
        button
        component={Link}
        to={props.to}
        selected={isActive}
        {...props.rest}
      >
        {props.children}
      </ListItem>
    );
  };

  const path = typeof props.to === 'object'
    ? props.to.pathname
    : props.to;

    // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
    const escapedPath =
      path && (path as string).replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

    return (
      <Route
        path={escapedPath}
        exact={props.exact}
        strict={props.strict}
        location={props.location}
        children={processChildren}
      />
    );
}


/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
export default NavLinkFn;
