import React from 'react';
import { get, isArray, isEmpty, isObject, isString } from 'lodash';
import Collapsable from './collapsable';

const JsonDisplay = (props: any) => {
  const writeProperty = (prop: any, level: number, key?: string): any => {
    if (isArray(prop)) {
      const collapseText = key ? `${key}: [ ... ]` : '[ ... ]';
      const openToken = key ? `${key}: [` : '[';
      const closeToken = ']';
      return (
        <Collapsable collapseText={collapseText} copyData={prop}>
          <span>{openToken}</span>
          {prop.map((p, i) => buildDom(i, p, level + 1))}
          <span>{closeToken}</span>
          {level > 0 && ','}
        </Collapsable>
      );
    } else if (isObject(prop)) {
      const openToken = key ? `${key}: {` : '{';
      const closeToken = '}';
      let collapseText = key ? `${key}: { ... }` : '{ ... }';
      if (props.collapseKeys) {
        let validKey = props.collapseKeys.find(
          (k: string) => get(prop, k, undefined) !== undefined,
        );
        if (validKey) {
          const val = get(prop, validKey);
          collapseText = key
            ? `${key}: { "${validKey}": ${val} }`
            : `{ "${validKey}": ${val} }`;
        }
      }
      return (
        <Collapsable collapseText={collapseText} copyData={prop}>
          <span>{openToken}</span>
          {Object.keys(prop).map((k, i) => buildDom(i, prop[k], level + 1, k))}
          <span>{closeToken}</span>
          {level > 0 && ','}
        </Collapsable>
      );
    } else {
      let pval = prop;
      if (isString(pval) || isEmpty(pval)) {
        pval = `"${pval}"`;
      }
      return (
        <div>
          {key && `${key}: `}
          {pval}
          {level > 0 && ','}
        </div>
      );
    }
  };
  const buildDom = (
    index: number,
    root: Object,
    level: number,
    key?: string,
  ) => {
    return (
      <div
        data-key={`${'' + level + index}`}
        key={`${'' + level + index}`}
        style={{ paddingLeft: `${0.5 * level}em` }}
      >
        {writeProperty(root, level, key)}
      </div>
    );
  };
  return buildDom(0, props.data, 0);
};

export default JsonDisplay;
