type Type = 'gateway' | 'driver' | 'gcss'

const normalizeVersion = (version:string, type?:Type) => {
  switch (type) {
    case 'gateway':
    case 'gcss':
      return version
        .replace(/^Kinestral linux distribution /, '')
        .replace(/ \\n \\l ?/, '');
    case 'driver':
      return version.replace(/\+.*/, '');
    default:
      return version;
  }
}


export { normalizeVersion};