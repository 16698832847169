import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  Button,
  Box,
  Tabs,
  Tab,
  Link,
} from '@material-ui/core';
import { useMutation, useQueries, useQuery } from 'react-query';

import {
  IApiResponse,
  IArchItem,
  ISite,
} from '@halio-inc/api-client';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingSpinner from 'components/LoadingSpinner';
import JsonDisplay from 'components/JsonDisplay';
import { flattenArchItems } from 'lib/shared/utility/arch-item';
import ApiClient from 'lib/web/api-client';

const formDefaults = {
  tab: 0,
  siteId: '',
  mac: '',
  cmdTarget: '',
  cmd: '',
  cmdArgs: '',
};
const refetchInterval = 5000; // 5s

interface CommandsResp {
  [cmdTarget:string]: {
    text: string;
    options: {
      [cmd:string]: {
        text: string,
        allowArgs: boolean,
      };  
    }
  }
}
interface CommandReq {
  gatewayMac: string;
  cmdTarget: string;
  cmd: string;
  commandArgs: string;
}
interface QueueResponse {
  queueID: string
  complete: boolean
  data:any
  archItem: any
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  loadIndicator: {
    textAlign: 'right',
    paddingBottom: theme.spacing(2),
  },
  actions: {
    textAlign: 'right',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  hidden: {
    display: 'none',
  },
  cmdRespContainer: {
    position: 'relative',
    overflow: 'auto',
    height: 500
  },
  cmdRespCopyBtn: {
    position: 'absolute',
    right: 0,
  }
}));

const GatewayTools: React.FC = () => {
  const siteService = ApiClient.getSites();
  const archItemsService = ApiClient.getArchItems();
  const classes = useStyles();

  const [useManual, setUseManual] = useState(formDefaults.tab);
  const [siteID, setSiteID] = useState(formDefaults.siteId);
  const [gatewayMac, setGatewayMac] = useState(formDefaults.mac);
  const [commandArgs, setCommandArgs] = useState(formDefaults.cmdArgs);
  const [cmdTarget, setCmdTarget] = useState(formDefaults.cmdTarget);
  const [cmd, setCmd] = useState(formDefaults.cmd);

  const [queues, setQueues] = useState<QueueResponse[]>([]);

  // Get all sites
  const {
    data: sites,
    isError: isErrorSites,
    isFetching: isFetchingSites,
  } = useQuery<IApiResponse<ISite[]>>(['sites'], () => siteService.getSites({ grant: 'shared-support' }));

  // Get all commands
  const {
    data: targetData,
    isError: isTargetError,
    isFetching: isFetchingCommands,
  } = useQuery<IApiResponse<CommandsResp>>(['gwctl-commands'], () => ApiClient.rawGet<CommandsResp>('admin/gateway/commands'));

  // Get site gateways
  const {
    data: gateways,
    isIdle: isIdleGateways,
    isError: isErrorGateways,
    isFetching: isFetchingGateways,
  } = useQuery<IApiResponse<IArchItem[]>>(['arch-items', siteID, 'gateways'], async () => {
    const items = await archItemsService.getArchItems({ siteId: siteID, types: ['gateway'] });
    return {
      ...items,
      results: flattenArchItems(items.results),
    }
  }, {
    enabled: siteID !== '',
  });

  // Send Command Request
  const commandResp = useMutation<IApiResponse<{queue: {[queueId:string]:any}; siteId: string}>, {}, CommandReq>((command) => {
    return ApiClient.rawPost(`admin/gateway/command/${command.gatewayMac}`, command);
  });
  const { isSuccess: isCmdSuccessful, data: cmdRespData } = commandResp;
  useEffect(() => {
    if (isCmdSuccessful && cmdRespData) {
      setSiteID(cmdRespData.results.siteId);
      const queueData = Object.entries(cmdRespData.results.queue).map(([q, archItem]) => ({
        queueID: q,
        complete: false,
        archItem,
        data: undefined,
      }));
      setQueues(queueData);
    }
  }, [isCmdSuccessful, cmdRespData])

  useQueries(queues.map(q => {
    return {
      queryKey: 'gwctl-commands-' + q.queueID,
      enabled: !q.complete,
      retry: false,
      refetchInterval,
      queryFn: () => ApiClient.rawGet(`sites/${siteID}/queue/${q.queueID}`),
      onSuccess: (d: any) => {
        if (d.message !== 'Still Processing') {
          setQueues(queues => {
            const qIdx = queues.findIndex(i => i.queueID === q.queueID);
            return [
              ...queues.slice(0, qIdx),
              {
                ...queues[qIdx],
                complete: true,
                data: d,
              },
              ...queues.slice(qIdx+1)
            ]
          })
        }
      }
    }
  }))

  // Form Change handlers
  const handleSetUseManual = useCallback((_, v) => {
    setUseManual(v);
  }, [setUseManual]);
  const handleSiteIDChange = useCallback(
    v => {
      setSiteID(v.target.value);
      setGatewayMac('');
    },
    [setGatewayMac, setSiteID],
  );
  const handleGatewayMacChange = useCallback(
    v => {
      setGatewayMac(v.target.value);
    },
    [setGatewayMac],
  );
  const handleCommandArgsChange = useCallback(
    v => {
      setCommandArgs(v.target.value);
    },
    [setCommandArgs],
  );
  const handleCmdTargetChange = useCallback(
    v => {
      setCmd('');
      setCommandArgs('');
      setCmdTarget(v.target.value);
    },
    [setCmdTarget],
  );
  const handleCmdChange = useCallback(
    v => {
      setCommandArgs('');
      setCmd(v.target.value);
    },
    [setCmd],
  );

  const submitCommand = useCallback(() => {
    // Return if required fields not given
    if (!gatewayMac || !cmdTarget || !cmd) {
      return;
    }
    commandResp.mutate({
      gatewayMac,
      cmdTarget,
      cmd,
      commandArgs,
    });
  }, [gatewayMac, commandArgs, cmdTarget, cmd, commandResp]);

  const resetFields = useCallback(() => {
    setUseManual(formDefaults.tab);
    setSiteID(formDefaults.siteId);
    setGatewayMac(formDefaults.mac);
    setCommandArgs(formDefaults.cmdArgs);
    setCmdTarget(formDefaults.cmdTarget);
    setCmd(formDefaults.cmd);
  }, [setUseManual, setSiteID, setGatewayMac, setCommandArgs, setCmdTarget, setCmd]);

  if (isFetchingCommands) {
    return <LoadingSpinner width="50px" height="50px" />
  }
  if (isTargetError || (targetData && !targetData.success)) {
    return <div>Error while getting commands to run</div>;
  }

  const cmdData = targetData ? targetData.results : { results: {} };
  const queueComplete = queues.reduce((a, v) => {
    if (v.complete) {
      a.numComp++;
    }
    a.complete = a.numComp === a.numTotal;
    return a;
  }, {complete: true, numComp: 0, numTotal: queues.length});
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{'Gateway Tools'}</Typography>
          <Paper className={classes.root}>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Grid container spacing={2}>
                    <Tabs
                      value={useManual}
                      onChange={handleSetUseManual}
                    >
                      <Tab label="Select Gateway" />
                      <Tab label="Enter Gateway MAC" />
                    </Tabs>
                    <Grid item sm={6} className={classnames({ [classes.hidden]: useManual })}>
                      {isErrorSites || (sites && !sites.success) ? (
                        <Box>{sites ? `Site Load Error: ${sites.message}` : 'Site list failed to load'}</Box>
                      ) : (
                        <FormControl fullWidth>
                          <InputLabel>Site Name*</InputLabel>
                          {isFetchingSites ? (
                            <LoadingIndicator />
                          ) : (
                            <Select
                              label="Site"
                              value={siteID}
                              onChange={handleSiteIDChange}
                            >
                              {sortBy((sites?.results ?? []), 'name').map(s => (
                                <MenuItem key={s.id} value={s.id}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          <FormHelperText>
                            Select site to filter to Gateway
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item sm={6} className={classnames({ [classes.hidden]: useManual })}>
                      {isErrorGateways || (gateways && !gateways.success) ? (
                        <Box>{gateways ? `Gateway Load Error: ${gateways.message}` : 'Gateway list failed to load'}</Box>
                      ) : (
                      <FormControl fullWidth>
                        <InputLabel>Gateway Name*</InputLabel>
                          {isFetchingGateways ? (
                            <LoadingIndicator />
                          ) : (
                          <Select
                            disabled={siteID === ''}
                            label="Gateway"
                            value={gatewayMac}
                            onChange={handleGatewayMacChange}
                          >
                            {(gateways?.results ?? []).map(g => g.hardwareId ? (
                              <MenuItem key={g.hardwareId} value={g.hardwareId}>
                                {g.name}
                              </MenuItem>
                            ): null)}
                            {isIdleGateways && (
                              <MenuItem value={gatewayMac}>"{gatewayMac}" Manually Entered</MenuItem>
                            )}
                          </Select>
                          )}
                        <FormHelperText>
                          Select Gateway to run command against
                        </FormHelperText>
                      </FormControl>
                      )}
                    </Grid>
                    <Grid item sm={12} className={classnames({ [classes.hidden]: !useManual })}>
                      <TextField
                        fullWidth
                        value={gatewayMac}
                        onChange={handleGatewayMacChange}
                        label="Gateway MAC*"
                        variant="standard"
                        helperText="Gateway to use for targeting site and device with commands"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Command Target*</InputLabel>
                        <Select
                          value={cmdTarget}
                          label="Command Target"
                          onChange={handleCmdTargetChange}
                        >
                          {Object.entries<any>(cmdData).map(([k, v]) => (
                            <MenuItem value={k} key={k}>{v.text}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          Target Device for Command
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl fullWidth disabled={!cmdTarget}>
                        <InputLabel>Command*</InputLabel>
                        <Select
                          value={cmd}
                          onChange={handleCmdChange}
                          label="Command"
                        >
                          {Object.entries<any>(get(cmdData, `${cmdTarget}.options`, {})).map(([k, c]) => (
                            <MenuItem key={k} value={k}>
                              {c.text}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Command Name to run</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        fullWidth
                        value={commandArgs}
                        onChange={handleCommandArgsChange}
                        disabled={
                          !get(cmdData, `${cmdTarget}.options.${cmd}.allowArgs`, false)
                        }
                        label="Commandline Arguments"
                        variant="standard"
                        helperText={get(cmdData, `${cmdTarget}.options.${cmd}.argsHelp`, '')}
                      />
                    </Grid>
                    <Grid item sm={12} className={classes.actions}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitCommand}
                      >
                        Send command
                      </Button>
                      <Button variant="outlined" onClick={resetFields}>Reset</Button>
                    </Grid>
                    <Grid item sm={12}>
                      For more information on available commands to be sent check this:&nbsp;
                      <Link target="_new" href='https://kinestralpg.atlassian.net/wiki/pages/viewpageattachments.action?pageId=2333179905&preview=/2333179905/2336980999/KinestralProdDriverProtocol_v23.pdf'>Confluence Doc</Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  {queueComplete.complete && (
                    <div>
                      {queueComplete.numTotal === 0 ? (
                        <div>
                          Response pending command
                        </div>
                      ) : (
                        <div className={classes.cmdRespContainer}>
                          <pre>
                            <JsonDisplay collapseKeys={['name', 'id']} data={queues.map(q => ({ arch: q.archItem, data: q.data}))} />
                          </pre>
                        </div>
                      )}
                    </div>
                  )}
                  {!queueComplete.complete && (
                    <div>
                      Command requests pending, checking again in {refetchInterval / 1000} seconds.
                      Completed {queueComplete.numComp} out of {queueComplete.numTotal}
                      <LoadingSpinner />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GatewayTools;
