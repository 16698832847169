import React from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  makeStyles
} from '@material-ui/core'

import DeviceCount from './device-count';
import Site from './site';
import VersionInfo from './version-info';
import SiteRow from './site-row';
import { ISiteSummary } from '../state';
import { IFlatSiteSummary } from './state';

interface ISiteTableProps {
  siteSummaries: ISiteSummary[]
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '25px 0 10px 0',
  },
  table: {
    height: 'calc(100vh - 300px)',
    minHeight: '300px',
    overflow: 'scroll',
  }
}));

const SiteTable: React.FC<ISiteTableProps> = ({ siteSummaries }) => {
  const classes = useStyles();
  const getCountValue = function (
    prop: string,
    num: string = 'online',
    den: string = 'total',
  ) {
    return function (row: any, index: any) {
      if (row[prop][den] === 0) {
        return 0;
      }
      return row[prop][num] / row[prop][den];
    };
  };
  const getCountFormatter = function (
    prop: string,
    num: string = 'online',
    den: string = 'total',
  ) {
    return function (args: any) {
      return (
        <DeviceCount
          count={args.row.original[prop][num]}
          total={args.row.original[prop][den]}
        />
      );
    };
  };
  const getCountSortType = function (
    prop: string,
    num: string = 'online',
    den: string = 'total',
  ) {
    return function (a: any, b: any, col: string, desc: boolean) {
      const shortCol = col.replace(/^col_devices_/, '');

      if (a.values[col] < b.values[col]) {
        return -1;
      } else if (b.values[col] < a.values[col]) {
        return 1;
      } else if (a.original[shortCol][num] < b.original[shortCol][num]) {
        return -1;
      } else if (b.original[shortCol][num] < a.original[shortCol][num]) {
        return 1;
      } else if (a.original[shortCol][den] < b.original[shortCol][den]) {
        return -1;
      } else if (b.original[shortCol][den] < a.original[shortCol][den]) {
        return 1;
      }

      return 0;
    };
  };

  const countSortTypeFns = {
    gateways: React.useCallback(getCountSortType('gateways'), []),
    drivers: React.useCallback(getCountSortType('drivers'), []),
    other: React.useCallback(getCountSortType('other'), []),
    battery: React.useCallback(getCountSortType('battery', 'ok'), []),
  };

  const columns: Column<{ [prop: string]: any }>[] = React.useMemo(
    () => [
      {
        id: 'col_site',
        Header: 'Site',
        accessor: 'site.name', // accessor is the "key" in the data
        Cell: args => {
          return <Site site={args.row.original.site} />;
        },
      },
      {
        id: 'col_devices_gateways',
        Header: 'Gateways',
        accessor: getCountValue('gateways'),
        sortType: countSortTypeFns['gateways'],
        Cell: getCountFormatter('gateways'),
      },
      {
        id: 'col_devices_drivers',
        Header: 'Drivers',
        accessor: getCountValue('drivers'),
        sortType: countSortTypeFns['drivers'],
        Cell: getCountFormatter('drivers'),
      },
      {
        id: 'col_devices_other',
        Header: 'Other',
        accessor: getCountValue('other'),
        sortType: countSortTypeFns['other'],
        Cell: getCountFormatter('other'),
      },
      {
        id: 'col_devices_battery',
        Header: 'Battery',
        accessor: getCountValue('battery', 'ok'),
        sortType: countSortTypeFns['battery'],
        Cell: getCountFormatter('battery', 'ok'),
      },
      {
        id: 'col_software_versions',
        Header: 'Software Versions',
        accessor: '', // TODO
        disableSortBy: true,
        Cell: args => {
          return (
            <VersionInfo
              manifests={args.row.original.manifests}
              versions={args.row.original.min_versions}
            />
          );
        },
      },
      {
        id: 'col_feature_glareControl',
        Header: 'Glare Control',
        accessor: 'features.glareControl',
        sortType: 'basic',
        Cell: args => (args.value ? 'Yes' : 'No'),
      },
    ],
    // eslint-disable-next-line
    [],
  );

  const data = React.useMemo(
    () =>
      siteSummaries.map<IFlatSiteSummary>(rec => {
        // return Object.assign({}, rec, { foo: 'bar' });
        return {
          site: rec.site,
          gateways: rec.devices.gateway,
          drivers: rec.devices.driver,
          other: rec.devices.other,
          battery: rec.battery,
          features: rec.feature,
          manifests: false,// rec.manifests,
          min_versions: rec.min_versions,
          enabled: rec.enabled,
          loaded: rec.loaded
        };
      }),
    [siteSummaries],
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className={classes.root}>
      <TableContainer className={classes.table} >
        <Table size="small" stickyHeader >
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell style={{ maxWidth: "20px" }} />
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps(
                      (column as any).getSortByToggleProps(),
                    )}
                  >
                    {column.render('Header')}
                    <span>
                      {(column as any).isSorted
                        ? (column as any).isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <SiteRow key={row.original.site.id} row={row} ></SiteRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SiteTable