import React from 'react';

import { ISite } from '@halio-inc/api-client';

interface ISiteLinkProps {
  site: ISite;
  children?: any
}

const SiteLink: React.FC<ISiteLinkProps> = ({ site, children }) => {
  let url = '';
  if (window.HALIO_CONFIG.DASHBOARD_BASE_URL) {
    url = window.HALIO_CONFIG.DASHBOARD_BASE_URL;
  }
  url = `${url}/${site.id}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default SiteLink