import React from 'react';

import { normalizeVersion } from './util';

import './index.css';

interface IVersionInfoProps {
  manifests: any[];
  versions: any;
}

const VersionInfo:React.FC<IVersionInfoProps> = ({ manifests, versions }) => {
  const showManifests = false; // TODO: Restore with this after MC-475: (props.manifests && props.manifests.length > 0);
  const showMinVersions =
    versions.gateway || versions.driver || versions.gcss;

  if (!showManifests && !showMinVersions) {
    return null;
  }

  const manifestsContent = showManifests ? (
    <div>
      Manifests:
      <ul>
        <li>Dummy Manifest Name 1</li>
        <li>Dummy Manifest Name 2</li>
        <li>Dummy Manifest name with longer name length</li>
      </ul>
    </div>
  ) : (
    ''
  );

  const minVersionsContent = showMinVersions ? (
    <div>
      Lowest installed version:
      <ul>
        {versions.gateway ? (
          <li>
            Gateway: {normalizeVersion(versions.gateway, 'gateway')}
          </li>
        ) : (
          ''
        )}
        {versions.driver ? (
          <li>Driver: {normalizeVersion(versions.driver, 'driver')}</li>
        ) : (
          ''
        )}
        {versions.gcss ? (
          <li>GCSS: {normalizeVersion(versions.gcss, 'gcss')}</li>
        ) : (
          ''
        )}
      </ul>
    </div>
  ) : (
    ''
  );

  return (
    <div className="software-versions-container">
      {manifestsContent}
      {minVersionsContent}
    </div>
  );
}

export default VersionInfo