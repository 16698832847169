import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { Layers, Settings } from '@material-ui/icons'
import clsx from 'clsx';
import NavLink from './nav-link';


const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));


interface INavProps
  extends React.HTMLAttributes<HTMLStyleElement> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Nav: React.FC<INavProps> = ({
  open,
  setOpen,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('shared');
  const isSmall = useMediaQuery<Theme>(t => t.breakpoints.down('sm'))

  const toggleDrawerState = useCallback(() => { setOpen(o => !o); }, [setOpen]);
  return (
    <Drawer
      anchor="left"
      variant={isSmall ? "temporary" : "permanent"}
      open={open}
      onClose={toggleDrawerState}
      className={clsx(className)}
      classes={{
        paper: clsx(className),
      }}
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <NavLink to="/dashboard">
          <ListItemIcon><Layers /></ListItemIcon>
          <ListItemText primary={t("Support Dashboard")} />
        </NavLink>
        <NavLink to="/admin/gateway-tools">
          <ListItemIcon><Settings /></ListItemIcon>
          <ListItemText primary={t("Gateway Tools")} />
        </NavLink>
      </List>
    </Drawer>
  );
}

export default Nav;
