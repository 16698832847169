import React, { useCallback, useState } from 'react';
import { IconButton } from '@material-ui/core';
import {
  FileCopy,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@material-ui/icons';

const Collapsable = (props: any) => {
  const copyAvailable = navigator.clipboard && window.isSecureContext;
  const { children, collapseText, copyData, ...rest } = props;
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = useCallback(
    () => {
      setCollapsed(c => !c);
    },
    [setCollapsed],
  );
  const copyContent = useCallback(
    () => {
      if (copyAvailable) {
        navigator.clipboard.writeText(JSON.stringify(copyData, undefined, 2));
      }
    },
    [copyAvailable, copyData],
  );
  return (
    <div {...rest}>
      {copyAvailable && (
        <IconButton
          size="small"
          onClick={copyContent}
          style={{ color: 'lightgrey' }}
        >
          <FileCopy />
        </IconButton>
      )}
      <IconButton size="small" onClick={toggleCollapse}>
        {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      </IconButton>
      <span style={{ display: collapsed ? 'initial' : 'none' }}>
        {collapseText}
      </span>
      <span style={{ display: collapsed ? 'none' : 'initial' }}>
        {children}
      </span>
    </div>
  );
};
export default Collapsable;
