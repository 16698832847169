import { makeStyles } from '@material-ui/core';
import { Check, ReportOutlined, ReportProblemOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';

interface IDeviceCountProps {
  count: number;
  total: number;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    verticalAlign: 'bottom',
  },
  warn: {
    color: theme.palette.warning.main
  },
  danger: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  }
}))

const DeviceCount: React.FC<IDeviceCountProps> = ({ count, total }) => {
  const classes = useStyles();
  const percent = total === 0 ? 1 : count / total;
  let warn = false;
  let success = false;

  let Icon = ReportOutlined;
  if (percent === 1) {
    success = true
    Icon = Check;
  } else if (percent >= 0.5) {
    // TODO: the above threshold is for demo purposes - threshold should be 0.9
    warn = true
    Icon = ReportProblemOutlined;
  }

  return (
    <div className="text-nowrap">
      <Icon fontSize="small" className={clsx(classes.icon, {
        [classes.warn]: warn,
        [classes.success]: success,
        [classes.danger]: !warn && !success,
      })} />
      {count} / {total}
    </div>
  );
}

export default DeviceCount