import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const backendOptions = {
  backend: {
    projectId: 'f097df6b-b76e-4361-a120-94304efca89e',
    version: 'production',
  },
};
const options = {
  ...backendOptions,
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false, // not needed for react as it excapes by default
  },
  react: {
    transSupportBasicHtmlNodes: true,
    useSuspense: true,
  },
  saveMissing: false,
  updateMissing: false,
};

i18n
  .use(LanguageDetector) // detect user language
  .use(LocizeBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options);

export default i18n;
