import React, { useCallback, useState } from 'react';
import { Cell, Row } from 'react-table';
import { Box, Collapse, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx';

import { IFlatSiteSummary } from './state';

interface ISiteRowProps {
  row: Row<IFlatSiteSummary>
}

const useStyles = makeStyles((theme) => ({
  moreCell: {
    maxWidth: '20px',
  },
  highlightRow: {
    backgroundColor: theme.palette.grey['100'],
  }
}))

const SiteRow: React.FC<ISiteRowProps> = ({ row }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(o => !o), []);
  const even = row.index % 2 === 0;
  const className = clsx(row.getRowProps().className, {
    [classes.highlightRow]: even
  })
  if (!row.original.enabled) {
    return null;
  }

  if (!row.original.loaded) {
    const siteCell = row.cells.find(c => c.column.id === 'col_site');
    if (!siteCell) {
      return (
        <TableRow {...row.getRowProps()} className={className}>
          <TableCell colSpan={row.cells.length}><Skeleton animation="wave" /></TableCell>
        </TableRow>
      )
    };
    return (
      <TableRow {...row.getRowProps()} className={className}>
        <TableCell className={classes.moreCell} />
        <TableCell {...siteCell?.getCellProps()}>
          {siteCell.render('Cell')}
        </TableCell>
        <TableCell colSpan={row.cells.length - 1}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow {...row.getRowProps()} className={className}>
        <TableCell className={classes.moreCell}>
          <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {row.cells.map((cell: Cell<IFlatSiteSummary>) => {
          return (
            <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
          );
        })}
      </TableRow>
      <TableRow className={className}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={row.cells.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ul>
                <li>
                  <label className="font-weight-bold">Site ID:</label>
                  {row.original.site.id}
                </li>
              </ul>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SiteRow;