import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  CssBaseline,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  useMediaQuery,
  Theme,
  Paper,
} from '@material-ui/core';
import clsx from 'clsx';

import { Auth, IAuth } from '@halio-inc/react-authentication';

import { Header, Nav } from 'theme/index';
import { SupportDashboard } from 'pages';
import GatewayTools from 'pages/admin/gateway-tools';

import LoadingSpinner from 'components/LoadingSpinner';
import AuthorizationProvider from 'lib/shared/authorization';
import ApiClient from 'lib/web/api-client';
import Callback from 'lib/web/components/callback';

import registerServiceWorker from './register-service-worker';
import './i18n';

declare global {
  interface Window {
    HALIO_CONFIG: {
      DASHBOARD_BASE_URL: string;
      API_V3: string;
    };
    mApp: {
      init: () => void;
    };
  }
}
interface AppProps {
  queryClient: QueryClient;
  auth: IAuth;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009bdb',
    },
  },
});

const drawerWidth = 240;

const useStyles = makeStyles(theme => {
  const mlLargeScreen = theme.spacing(7) + 1;
  const mlSmallScreen = theme.spacing(9) + 1;
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      right: 'inherit',
      marginLeft: mlLargeScreen,
      [theme.breakpoints.up('sm')]: {
        marginLeft: mlSmallScreen,
      },
      width: `calc(100% - ${mlSmallScreen}px)`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: mlLargeScreen,
      [theme.breakpoints.up('sm')]: {
        width: mlSmallScreen,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    main: {
      padding: theme.spacing(3),
      flexGrow: 1,
    },
    footer: {
      flexGrow: 0,
    },
    footerContent: {
      height: '60px',
      padding: '7px 30px',
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const Content: React.FC = () => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>(t => t.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(true);
  useEffect(
    () => {
      if (isSmall) {
        setDrawerOpen(false);
      }
    },
    [isSmall],
  );
  return (
    <div style={{ display: 'flex' }}>
      <Suspense fallback={<div className={classes.appBar} />}>
        <Header
          setDrawerOpen={setDrawerOpen}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawerOpen,
          })}
        />
      </Suspense>
      <Suspense fallback={<div className={classes.drawer} />}>
        <Nav
          open={drawerOpen}
          setOpen={setDrawerOpen}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
        />
      </Suspense>
      <div className={classes.content}>
        <Suspense fallback={<LoadingSpinner width="50px" height="50px" />}>
          <div className={classes.toolbar} />
          <main className={classes.main}>
            <Switch>
              <Route
                exact={true}
                path="/callback"
                render={(props: any) => <Callback {...props} />}
              />
              <Route
                exact={true}
                path="/dashboard"
                component={SupportDashboard}
              />
              <Route
                exact={true}
                path="/admin/gateway-tools"
                component={GatewayTools}
              />
              <Redirect to="/dashboard" />
            </Switch>
          </main>
          <footer className={classes.footer}>
            <Paper className={classes.footerContent}>
              <span>
                {new Date().getFullYear()} &copy;
                <a
                  href="https://www.halioglass.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  &nbsp;Halio
                </a>
              </span>
            </Paper>
          </footer>
        </Suspense>
      </div>
    </div>
  );
};

const App: React.FC<AppProps> = ({ queryClient: qc, auth }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={qc}>
          <CssBaseline />
          <BrowserRouter>
            <AuthorizationProvider auth={auth} client={ApiClient}>
              <Content />
            </AuthorizationProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

// Setup auth controller
const authController = new Auth();

// Setup Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <App auth={authController} queryClient={queryClient} />,
  document.getElementById('root'),
);

registerServiceWorker();
