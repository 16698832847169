import React from 'react';
import loading from './loading.svg';

class Callback extends React.Component {
  public render() {
    const style = {};

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}

export default Callback;
