import React from 'react';
import { ISite } from '@halio-inc/api-client';

import SiteLink from './site-link';

interface ISiteProp {
  site: ISite;
}

const Site:React.FC<ISiteProp> = ({ site }) => {
  return (
    <div>
      <SiteLink site={site}>
        {site.name || '[Unnamed site]'}
      </SiteLink>
    </div>
  );
}


export default Site