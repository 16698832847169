import React, { useState, createContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { IAuth } from '@halio-inc/react-authentication';
import { ApiClient, IAccount, IApiResponse } from '@halio-inc/api-client';

import debug from 'lib/debug';
import LoadingIndicator from 'components/LoadingIndicator';
const createDebug = debug('lib:shared');
const log = createDebug('authorization');

interface IAuthorizationProps {
  auth: IAuth;
  client: ApiClient;
}
interface IUserContext {
  user: IAccount | null;
  userLoading: boolean;
  logout: Function;
}

export const AuthorizationContext = createContext<IUserContext>({
  user: null,
  userLoading: true,
  logout: () => {},
});

const AuthorizationProvider: React.FC<IAuthorizationProps> = ({
  auth,
  client,
  children,
}) => {
  const history = useHistory();
  const [state, setState] = useState<IUserContext>({
    user: null,
    userLoading: true,
    logout: () => {},
  });
  useEffect(
    () => {
      const accountsService = client.getAccounts();
      auth.setSessionSetCallback(accessToken =>
        client.setAccessToken(accessToken),
      );
      client.setAuthErrorCallback(async () => {
        return (await auth.getAccessToken()) || '';
      });
      const authCheck = async () => {
        let redirectUrl: string = '/';
        try {
          redirectUrl = await auth.handleAuthentication();
        } catch (e) {
          log(e);
        }
        const isAuthenticated: boolean = await auth.isAuthenticated();
        if (!isAuthenticated) {
          auth.login();
          return;
        }
        if (window.location.pathname === '/callback') {
          history.replace(redirectUrl);
        }
        const token: string | null = await auth.getAccessToken();
        if (token === null) {
          auth.logout();
          return;
        }
        client.setAccessToken(token as string);

        // Get the user. If it fails log them out, otherwise set them
        const account: IApiResponse<
          IAccount
        > = await accountsService.getAccountInfo({});
        if (account.success) {
          setState({
            user: account.results,
            userLoading: false,
            logout: auth.logout,
          });
        } else {
          auth.logout();
        }
      };
      authCheck();
    },
    [auth, client, setState, history],
  );

  if (state.userLoading) {
    return <LoadingIndicator />;
  }

  return (
    <AuthorizationContext.Provider value={state}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationProvider;
