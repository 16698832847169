import React, { useMemo, useState, useRef, useCallback, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles, Menu, MenuItem, useMediaQuery, Theme, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, Translate } from '@material-ui/icons';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  whiteText: {
    color: theme.palette.common.white
  }
}))

const SupportedLanguages = [
  {
    languageCode: 'en-us',
    languageName: 'language-list-label-english',
    nativeLangTitle: 'English',
  },
  {
    languageCode: 'zh-hans',
    languageName: 'language-list-label-chinese-simplified',
    nativeLangTitle: '简体中文）',
  },
  {
    languageCode: 'zh-hant-tw',
    languageName: 'language-list-label-chinese-traditional',
    nativeLangTitle: '中国传统的）',
  },
  {
    languageCode: 'ja-jp',
    languageName: 'language-list-label-japanese',
    nativeLangTitle: '日本語',
  },
  {
    languageCode: 'ko',
    languageName: 'language-list-label-korean',
    nativeLangTitle: '한국어',
  },
  {
    languageCode: 'fr',
    languageName: 'language-list-label-french',
    nativeLangTitle: 'français',
  },
  {
    languageCode: 'de-de',
    languageName: 'language-list-label-german',
    nativeLangTitle: 'Deutsche',
  },
  {
    languageCode: 'es-gt',
    languageName: 'language-list-label-spanish',
    nativeLangTitle: 'Español',
  },
];

interface ILanguageTextSwitcherProps extends HTMLAttributes<HTMLStyleElement> { }

const LanguageTextSwitcher: React.FC<ILanguageTextSwitcherProps> = ({
  className
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('shared')
  const currentLanguage = (window.localStorage.i18nextLng ?? i18n.language).toLowerCase();
  const activeLanguageName = useRef(currentLanguage);

  const isSmall = useMediaQuery<Theme>(t => t.breakpoints.down('sm'))
  const [menuAnchor, setMenuAnchor] = useState(null);
  const openDropdown = useCallback((event) => { setMenuAnchor(event.currentTarget); }, [setMenuAnchor]);
  const closeDropdown = useCallback(() => setMenuAnchor(null), []);

  const onHandleClick = useCallback((langCode: string) => {
    i18n.changeLanguage(langCode);
    closeDropdown();
  }, [i18n, closeDropdown]);

  const dropdownOptions = useMemo(() => SupportedLanguages.map(language => {
    const languageName = currentLanguage === 'en-us' ? t(language.languageName) : language.nativeLangTitle;
    const countryCode = language.languageCode;
    const isActive = currentLanguage === countryCode;
    if (isActive) {
      activeLanguageName.current = languageName;
    }

    return {
      languageName,
      countryCode,
      isActive,
      language,
    };
  }), [t, currentLanguage]);

  return (
    <>
      {isSmall ? (
        <IconButton
          onClick={openDropdown}
          className={clsx(className, classes.whiteText)}
        >
          <Translate />
        </IconButton>
      ) : (
        <Button
          onClick={openDropdown}
          className={clsx(className, classes.whiteText)}
          endIcon={<KeyboardArrowDown />}
        >
          {activeLanguageName.current}
        </Button>
      )}
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={closeDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
      >
        {dropdownOptions.map(o => (
          <MenuItem key={o.countryCode} selected={o.isActive} onClick={() => onHandleClick(o.countryCode)}>
            {o.languageName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageTextSwitcher;
